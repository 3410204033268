<!--
 * @Description: 动态渲染配置页面
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-20 10:36:58
-->
<template>
	<div
		class="index"
		v-loading="loading || globalLoading"
		:style="{
			height: pageHeight
		}"
	>
		<Parser v-if="inited" :canvasStyleOut="canvasStyle"></Parser>
	</div>
</template>

<script>
import { getRenderData } from '@/apis/data/index';
import Parser from '@/components/parser/Index';
import { isJSONStr } from '@/utils/tools';
import { mapState } from 'vuex';
import { deepClone } from '@topology/core';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'Index',
	components: {
		Parser
	},
	props: {
		pageUUID: {
			type: String,
			required: false,
			default: ''
    }
  },
	data() {
		return {
			inited: false,
			loading: false,
			canvasStyle: {},
			hidePadding: false,
      globalLoading: false
		};
	},
	computed: {
		...mapState(['currentApp', 'currentMenu', 'systemConfig']),
		// 根据当前点击的顶部菜单判断是否内容区全屏显示
		isFullScreen() {
			const originData = this.currentApp?.app_orign_data || [];
			if (!Array.isArray(originData) || !originData.length) return false;
			if (+originData[0].is_screen === 1) {
				return true;
			}
			const childrenList = originData[0].children_list || [];
			const childrenLevelOne = childrenList.filter(ele => +ele.level === 1);
			let currentMenuLevel = childrenLevelOne.find(ele => ele.id === this.currentMenu?.id);
			if (currentMenuLevel) {
				return !!currentMenuLevel?.is_screen;
			}
			// 顶部二级菜单的直接层次
			const childrenLevelTwo = childrenList.filter(ele => +ele.level === 2);
			currentMenuLevel = childrenLevelTwo.find(ele => ele.id === this.currentMenu?.id);
			if (currentMenuLevel) {
				return !!currentMenuLevel?.is_screen;
			}
			return false
		},
		// 页面高度
		pageHeight() {
			const { theme_type } = this.systemConfig;
			if (this.isFullScreen) {
				if (this.hidePadding) {
					return 'calc(100vh - 48px)';
				}
				return 'calc(100vh - 80px)';
			}
			// if (this.pageUUID) {
			// 	return 'calc(100vh - 140px)';
			// }
			// return 'calc(100vh - 130px)';
			if (theme_type === 2) {
				if (!this.pageUUID) {
					return  this.isFullScreen ? 'calc(100vh - 136px)' : '100%'
				}
			}
			if (theme_type === 4) {
				if (!this.pageUUID) {
					return  this.isFullScreen ? 'calc(100vh - 78px)' : '100%'
				}
			}
			if (!this.pageUUID) {
				// 新主题带历史记录
				const { theme_type, theme_append_info } = this.systemConfig;
				if (theme_type === 3 && theme_append_info?.needHistory) {
					return this.isFullScreen ? 'calc(100vh - 117px)' : 'calc(100vh - 127px)';
				}
				if (this.hidePadding) {
					return 'calc(100vh - 48px)';
				}
				return this.isFullScreen ? 'calc(100vh - 80px)' : 'calc(100vh - 80px)';
			}
			return '100%'
		}
	},
	created() {
		// 移除部分缓存数据，避免直接刷新页面的相关问题
		sessionStorage.removeItem('currentFormUUID')
		this.hidePadding = !!(+this.$route?.query?.hidePadding === 1);
		this.getViewData();
	},
	mounted() {
		eventBus.$on('dataviewGlobalLoading', ({ loading }) => {
			this.globalLoading = loading;
		});
	},
	watch: {
		$route: {
			handler() {
				this.hidePadding = !!(+this.$route?.query?.hidePadding === 1);
				this.reRender();
			},
			deep: true
		}
	},
	methods: {
		/**
		 * @desc: 获取dataView配置数据
		 */
		getViewData() {
			this.loading = true;
			this.$store.commit('clearConfigData');
			getRenderData({
				__method_name__: 'dataList',
				page_uuid: this.pageUUID || this.$route.query.pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			})
				.then((res) => {
					this.loading = false;
					const { page_data } = res?.data?.data?.[0] || {};
					const {
						componentData,
						editorConfig,
						editorType,
						statusConfig = [],
						globalInterval = {}
					} = isJSONStr(page_data) ? deepClone(JSON.parse(page_data)) : deepClone(page_data);
					this.canvasStyle = editorConfig || {};
          sessionStorage.setItem('statusConfig', JSON.stringify(statusConfig));
          this.$store.commit('setGlobalInterval', globalInterval);
					this.$nextTick(() => {
						this.$store.commit('setConfigData', {
							componentData,
							editorConfig,
							editorType
						});
						this.inited = true;
					});
				})
				.catch(() => {
					this.loading = false;
				});
		},
		/**
		 * @desc: 重新渲染
		 */
		reRender() {
			this.inited = false;
			this.getViewData();
		}
	},
	beforeDestroy() {
		this.globalLoading = false;
		eventBus.$off('dataviewGlobalLoading');
	}
};
</script>

<style lang="less" scoped>
.index {
 width: 100%;
 // height: calc(100vh - 130px);
 overflow: hidden;
 overflow-y: auto;
 box-sizing: border-box;
//  padding: 10px;
}
</style>